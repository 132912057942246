<script setup lang="ts">
import type { CmsElementProductSlider } from '@shopware-pwa/composables-next';
import { useWishlist } from '@shopware-pwa/composables-next';
import { composeProductBadges } from '@/helpers/composeProductBadges';
import { getProductProperties } from '@/helpers/getProductProperties';
import { EBrand } from '@/types/brand';
import { EIcon } from '@/types/icon';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';

const { locale } = useI18n();
const router = useRouter();

const props = defineProps<{
  content: CmsElementProductSlider;
}>();

const productSlider = ref<HTMLElement>();
const products = computed(() => props.content?.data?.products ?? []);

/**
 * Declare transliteration and notification helpers
 */
const { t } = useI18n();
const { pushSuccess } = useNotifications();

/**
 * Declare wishlists handlers
 */
const wishlistProduct = ref();
const { isInWishlist, addToWishlist, removeFromWishlist } =
  useProductWishlist(wishlistProduct);

const { items } = useWishlist();
const toggleToFavorites = async (product: any) => {
  // wishlistProduct.value = await product;
  wishlistProduct.value = product;
  if (isInWishlist.value) {
    await removeFromWishlist();
    await pushSuccess(t('components.wishlist.removeFromWishlist'));
  } else {
    await addToWishlist();
    await pushSuccess(t('components.wishlist.addToWishlist'));
  }
};

const openProduct = (product: any) => {
  router.push(product.link);
};

/**
 * Declare slider items data
 */
const sliderData = computed(() => {
  return products.value.map((product) => {
    const thumbnail400 = product.cover?.media?.thumbnails.find(
      (thumbnail) => thumbnail.width === 400
    );
    const mediaSrc = thumbnail400
      ? thumbnail400.url
      : product.cover?.media?.url;
    const {
      productColorName,
      productMaterial,
      productAdditionalInfo,
      productShoeType,
      productShoeClosure,
    } = getProductProperties(
      product.properties || [],
      (product.manufacturer?.customFields?.ln_brand_internal_name as EBrand) ||
        EBrand.LEGERO
    );

    const badgeList: any = composeProductBadges(product, t);

    const { regularPrice, specialPrice } = useCustomPrices(product);
    // TODO: make product color in name based on product extend plugin LSWS-496

    const productExtraInfo = [
      productShoeType,
      productMaterial,
      productShoeClosure,
    ]
      .filter(Boolean)
      .join(', ');

    return {
      id: product.id,
      src: mediaSrc,
      srcset: product.cover?.media?.thumbnails
        ? getSrcSetForMedia(product.cover?.media as any)
        : '',
      productName: product.translated.name + ' - ' + productColorName || '',
      productAlt: product.cover?.media?.alt,
      productTitle: product.cover?.media?.title,
      // link: product.seoUrls
      //   ? `/${locale.value}/${product?.seoUrls[0]?.seoPathInfo}`
      //   : '',
      link:
        `/${locale.value}/${product?.seoUrls?.[0]?.seoPathInfo}` ||
        `/${locale.value}${product?.seoUrls?.[0]?.pathInfo}` ||
        '',
      promotion: productAdditionalInfo,
      regularPrice: regularPrice(),
      specialPrice: specialPrice(),
      badgeList: badgeList,
      inWishList: items.value.includes(product.id),
      article: productExtraInfo,
    };
    // FIXME: read currency symbol from data
  });
});

// FIXME: warning for type any
const config: any = ref(props.content.config);

const getConfigValue = (key: string) => {
  // return props.content.config[key]?.source !== 'mapped' && props.content.config[key]?.value;
  return config.value[key]?.source !== 'mapped' && config.value[key]?.value;
};

// info: rotationSpeed, numberOfSlides, eventFlags, displayPrice not default values in ProductSliderElementConfig
// legero-frontends/node_modules/@shopware-pwa/composables-next/dist/index.d.ts
const autoplay = computed(() => getConfigValue('rotate'));
const autoplaySpeed = computed(() => getConfigValue('rotationSpeed'));
const title = computed(() => getConfigValue('title'));
const numberOfSlides = computed(() => getConfigValue('numberOfSlides'));
const navigation = computed(() => getConfigValue('navigation'));
const eventFlags = computed(() => getConfigValue('eventFlags'));
const displayPrice = computed(() => getConfigValue('displayPrice'));

const getBreakpoints = ():
  | Record<
      string,
      {
        slides?: {
          origin?: 'auto' | 'center' | number;
          perView?: number;
          spacing?: number;
        };
      }
    >
  | undefined => {
  if (numberOfSlides.value === '3') {
    return {
      '(max-width: 767px)': {
        slides: {
          origin: 0,
          perView: 2.5,
          spacing: 0,
        },
      },
      '(min-width: 768px)': {
        slides: {
          origin: 0,
          perView: 3.5,
          spacing: 0,
        },
      },
      '(min-width: 1024px)': {
        slides: {
          origin: 0,
          perView: 3,
          spacing: 0,
        },
      },
    };
  } else if (numberOfSlides.value === '4') {
    return {
      '(max-width: 767px)': {
        slides: {
          origin: 0,
          perView: 2.5,
          spacing: 0,
        },
      },
      '(min-width: 768px)': {
        slides: {
          origin: 0,
          perView: 3.5,
          spacing: 0,
        },
      },
      '(min-width: 1024px)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 0,
        },
      },
    };
  } else if (numberOfSlides.value === '5') {
    return {
      '(max-width: 767px)': {
        slides: {
          origin: 0,
          perView: 2.5,
          spacing: 0,
        },
      },
      '(min-width: 768px)': {
        slides: {
          origin: 0,
          perView: 3.5,
          spacing: 0,
        },
      },
      '(min-width: 1024px)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 0,
        },
      },
    };
  } else {
    // backend proviedes default of 4 for numberOfSlides
    return undefined;
  }
};
</script>
<template>
  <div ref="productSlider" class="cms-element-product-slider">
    <ProductSlider
      :title="title"
      :autoplay="autoplay"
      :autoplay-speed="autoplaySpeed * 1000"
      :arrows="navigation"
      :hide-price="!displayPrice"
      :hide-badges="!eventFlags"
      :slider-data="sliderData"
      :breakpoints="getBreakpoints()"
      @open-product="openProduct"
      @toggle-to-favorites="toggleToFavorites"
    >
      <template #wishlist="{ withWishIcon, toggleWishlist, inWishlist }">
        <ClientOnly>
          <Button
            v-if="withWishIcon"
            pure
            type="button"
            class="card-product-picture__like"
            @click.stop.prevent="toggleWishlist"
          >
            <IconSprite
              :name="inWishlist ? EIcon.HEART_FILLED : EIcon.HEART"
              icon600
            />
          </Button>
        </ClientOnly>
      </template>
    </ProductSlider>
  </div>
</template>
